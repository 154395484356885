import React from "react";

const Header = () => {
  return (
    <div
      id="shadow_header"
      className="w-full h-[90px]  bg_header    flex  justify-center lg:justify-start items-center "
    >
      <h1 className="ml-[15px] font-bold text-xl border_header ">
        V&G FLOORING LLC{" "}
      </h1>
    </div>
  );
};

export default Header;

import React from "react";

const Location = () => {
  return (
    <div className="w-full h-[80vh] flex justify-center  items-center flex-col">
      <div className="w-auto h-">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3089.0304664430987!2d-76.52736032361554!3d39.264875324937215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8013864160d6d%3A0x1667addbfa39bc59!2s21%20Yorkway%2C%20Dundalk%2C%20MD%2021222%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sdo!4v1702518826594!5m2!1ses-419!2sdo"
          width="400"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="mt-4">
        <p className="text-2xl">Our address</p>
      </div>
    </div>
  );
};

export default Location;

import React from "react";

const Footer = () => {
  return (
    <div className="w-full h-[35vh] bg_footer flex justify-center items-center flex-col p-4">
      <div className="text-7xl text-white">
        <p>V&G FLOORING LLC</p>
      </div>
      <div>
        <p className="text-white mt-4">NUMBER: +929-598-3459</p>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="homeDiv">
      <div className="homeDivBg">
        <div>
          <p className=" text-7xl font-bold">
            Get to know V&G <br /> flooring LLC
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
